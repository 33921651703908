<template>
  <client-only>
    <div class="error-page">
      <div class="error-page__bg" />
      <div class="error-page__content">
        <Icon404 class="icon" />
        <span>Không tìm thấy trang, vui lòng quay lại sau!</span>
        <BaseButton class="base-button--btn-primary" @click="goHome">trang chủ</BaseButton>
      </div>
    </div>
  </client-only>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import BaseButton from '~/components/common/base-button.vue'
import Icon404 from '~/components/desktop/error/Icon404.vue'

const router = useRouter()
const goHome = () => {
  router.push('/')
}

const toggleBodyClass = (className, add) => {
  document.body.classList[add ? 'add' : 'remove'](className)
}

onMounted(() => toggleBodyClass('fix-screen', true))
onBeforeUnmount(() => toggleBodyClass('fix-screen', false))
</script>

<style scoped lang="scss" src="~/public/assets/scss/page/error-mb.scss"></style>
